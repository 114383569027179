import React, { useEffect } from 'react';
const BattleRoyal = () => {
  useEffect(() => {
    if (window) {
      window.location.replace('/energy-battle-royale');
    }
  })
  return (
    <div></div>
);
}

export default BattleRoyal;


